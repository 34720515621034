import { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Field,
    Input,
    Checkbox,
    Dropdown,
    Option,
} from "@fluentui/react-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useBoolean } from "../../hooks/useBoolean";
import { useDialogStyles } from "../../hooks/useDialogStyles";
import { Event, MailerLiteGroup } from "../../models/Admin";
import { MarkdownEditor } from "../../components/MarkdownEditor";
import * as api from "../../utils/api";
import { ApiValidationError, EntityWithValidation, getEntityWithValidation } from "../../models/ValidatedEntity";

type EventNew = Omit<Event, "id" | "timestamp">;
type EventUpdate = Omit<Event, "id" | "slug" | "timestamp">;

export const AdminEventEdit = () => {
    const { eventSlug } = useParams() as { eventSlug: string };
    const navigate = useNavigate();
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const [event, setEvent] = useState<Event | null>(null);
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);
    const [mailerLiteGroups, setMailerLiteGroups] = useState<MailerLiteGroup[]>([]);
    const [showSavedBanner, setShowSavedBanner] = useState(false);

    useEffect(() => {
        async function execute() {
            setActivityMessage("loading...");
            setError(null);

            setMailerLiteGroups(await api.get<MailerLiteGroup[]>(`api/admin/mailerlite/groups`)); // load before event (for dropdown)
            try {
                const e = await api.get<Event>(`api/admin/events/${eventSlug}`);
                setEvent(e);
                setValidationErrors([]);
            } catch (e) {
                setError(e as Error);
            }
            setActivityMessage(null);
        }
        execute();
    }, [eventSlug]);

    const saveEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        setError(null);
        setActivityMessage("saving...");
        try {
            if (!event) {
                throw new Error("Cannot save null event");
            }
            const { id, slug, timestamp, ...eventUpdate } = event;
            const response = await api.put<EventUpdate, Event>(`api/admin/events/${eventSlug}`, eventUpdate);
            if ("errors" in response) {
                setValidationErrors(response.errors);
            } else {
                setEvent(response);
                setValidationErrors([]);
                setShowSavedBanner(true);
                setTimeout(() => setShowSavedBanner(false), 1000);
            }
            setError(null);
            setActivityMessage(null);
        } catch (e) {
            setError(e as Error);
            setActivityMessage(null);
        }
    };

    const entityWithValidation = event && getEntityWithValidation(event, validationErrors);

    const styles = useDialogStyles();

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / <Link to="/admin/events">events</Link> /{" "}
                <Link to={`/admin/events/${eventSlug}`}>{event?.title}</Link>
            </h1>
            {showSavedBanner && <div className={styles.savedBanner}>Saved!</div>}
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>{error.message}</div>}

            {entityWithValidation && (
                <AdminEventEditComponent
                    isNew={false}
                    entityWithValidation={entityWithValidation}
                    mailerLiteGroups={mailerLiteGroups}
                    setEvent={setEvent}
                    onSaveEntity={saveEntity}
                    onDiscardChanges={() => navigate(`/admin/events`)}
                />
            )}

            <h2>Related:</h2>
            <ul className="event-related-list">
                <li>
                    <Link to={`/admin/events/${eventSlug}/options`}>Go to booking options...</Link>
                </li>
                <li>
                    <Link to={`/admin/events/${eventSlug}/coupons`}>Go to booking coupons...</Link>
                </li>
                {event && (
                    <>
                        <li>
                            <Link to={`/admin/bookings?eventId=${event?.id}`}>Go to bookings...</Link>
                        </li>
                        <li>
                            <Link to={`/admin/audit-entries?eventId=${event?.id}`}>Go to audit entries...</Link>
                        </li>
                    </>
                )}
            </ul>
        </>
    );
};

export function AdminEventNew() {
    const { eventSlug } = useParams() as { eventSlug: string };
    const navigate = useNavigate();
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const [event, setEvent] = useState<Event>({
        id: "",
        timestamp: "",
        slug: "",
        title: "",
        description: "",
        placesLeft: 0,
        totalPlaces: 0,
        archived: false,
        mailerliteGroupId: "",
    });
    const [validationErrors, setValidationErrors] = useState<ApiValidationError[]>([]);
    const [mailerLiteGroups, setMailerLiteGroups] = useState<MailerLiteGroup[]>([]);

    useEffect(() => {
        async function execute() {
            setActivityMessage("loading...");
            setError(null);

            setMailerLiteGroups(await api.get<MailerLiteGroup[]>(`api/admin/mailerlite/groups`));
            setActivityMessage(null);
        }
        execute();
    }, [eventSlug]);

    const saveEntity = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        setError(null);
        setActivityMessage("saving...");
        try {
            if (!event) {
                throw new Error("Cannot save null event");
            }
            const { id, timestamp, ...eventNew } = event;
            const response = await api.post<EventNew, Event>(`api/admin/events`, eventNew);
            if ("errors" in response) {
                setValidationErrors(response.errors);
            } else {
                navigate(`/admin/events/${response.slug}`);
                return;
            }
            setError(null);
            setActivityMessage(null);
        } catch (e) {
            setError(e as Error);
            setActivityMessage(null);
        }
    };
    const entityWithValidation = event && getEntityWithValidation(event, validationErrors);

    const styles = useDialogStyles();

    return (
        <>
            <h1>
                <Link to="/admin">admin</Link> / <Link to="/admin/events">events</Link> /{" "}
                <Link to={`/admin/events/${eventSlug}`}>{event?.title}</Link>
            </h1>
            {activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
            {error && <div className={styles.errorBanner}>{error.message}</div>}

            {entityWithValidation && (
                <AdminEventEditComponent
                    isNew={true}
                    entityWithValidation={entityWithValidation}
                    mailerLiteGroups={mailerLiteGroups}
                    setEvent={setEvent}
                    onSaveEntity={saveEntity}
                    onDiscardChanges={() => navigate(`/admin/events`)}
                />
            )}
        </>
    );
}

function AdminEventEditComponent({
    entityWithValidation,
    mailerLiteGroups,
    isNew,
    setEvent,
    onSaveEntity,
    onDiscardChanges,
}: {
    entityWithValidation: EntityWithValidation<Event>;
    mailerLiteGroups: MailerLiteGroup[];
    isNew: boolean;
    setEvent: (e: Event) => void;
    onSaveEntity: () => void;
    onDiscardChanges: () => void;
}) {
    const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
        useBoolean(false);

    const event = entityWithValidation.entity;
    const styles = useDialogStyles();

    const selectedMailerLiteGroup = event ? mailerLiteGroups?.find((g) => g.id === event.mailerliteGroupId)?.name : undefined;
    return (
        <>
            <div className={styles.root + " event-form"}>
                {entityWithValidation.entityMessage && <div>Errors: {entityWithValidation.entityMessage}</div>}
                {!isNew && (
                    <Field label="Booking link" orientation="vertical" className={styles.field}>
                        {/* TODO: handle local vs dev vs live */}
                        <a href={`https://bookings.emilieleeks.com/events/${event.slug}`}>
                            https://bookings.emilieleeks.com/events/{event.slug}
                        </a>
                    </Field>
                )}
                <Field
                    label="Slug"
                    hint="The slug is the part of the url for the event, e.g. my-cool-event"
                    orientation="vertical"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.slug}
                >
                    <Input
                        defaultValue={event.slug}
                        readOnly={!isNew}
                        onChange={(_, data) => {
                            if (isNew) {
                                setEvent({ ...event, slug: data.value });
                            }
                        }}
                    />
                </Field>
                <Field
                    label="Title"
                    hint="This is the title for pages that show the event"
                    orientation="vertical"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.title}
                >
                    <Input defaultValue={event.title} onChange={(_, data) => setEvent({ ...event, title: data.value })} />
                </Field>
                <Field
                    label="Description"
                    hint="Description of the event"
                    orientation="vertical"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.description}
                >
                    <MarkdownEditor
                        value={event.description ?? ""}
                        onChange={(data) => setEvent({ ...event, description: data.value })}
                        editorClassName="event-edit-markdown"
                    />
                </Field>
                <Field
                    label="Places Left"
                    hint="The current number of places left for the event (note that bookings in progress are not counted here"
                    orientation="vertical"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.placesLeft}
                >
                    <Input
                        defaultValue={event.placesLeft.toString()}
                        onChange={(_, data) => setEvent({ ...event, placesLeft: parseInt(data.value) })}
                    />
                </Field>
                <Field
                    label="Total Places"
                    hint="The total number of places for the event"
                    orientation="vertical"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.totalPlaces}
                >
                    <Input
                        defaultValue={event.totalPlaces.toString()}
                        onChange={(_, data) => setEvent({ ...event, totalPlaces: parseInt(data.value) })}
                    />
                </Field>
                <Field
                    label="MailerLite Group"
                    hint="The MailerLite group to add the customer to when they book"
                    orientation="vertical"
                    className={styles.field}
                    required={false}
                    validationMessage={entityWithValidation.propertyMessages.mailerliteGroupId}
                >
                    <Dropdown
                        defaultValue={selectedMailerLiteGroup}
                        onOptionSelect={(_, data) => {
                            setEvent({ ...event, mailerliteGroupId: data.optionValue ?? "" });
                        }}
                    >
                        <Option text="" value="" />
                        {mailerLiteGroups?.map((g) => (
                            <Option key={g.id} text={g.name} value={g.id}>
                                {g.name}
                            </Option>
                        ))}
                    </Dropdown>
                </Field>
                <Field
                    label="Archived"
                    hint="Archiving an event prevents it from being shown in the admin list (or being booked on)"
                    orientation="vertical"
                    className={styles.field}
                    required={true}
                    validationMessage={entityWithValidation.propertyMessages.archived}
                >
                    <Checkbox
                        defaultChecked={event.archived}
                        onChange={(_, data) => setEvent({ ...event, archived: data.checked === true })}
                    />
                </Field>

                <div className={styles.actions}>
                    <Button appearance="primary" onClick={onSaveEntity}>
                        Save
                    </Button>
                    <Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog}>
                        Cancel
                    </Button>
                    <Dialog open={isDiscardConfirmationDialogOpen}>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle>Discard changes?</DialogTitle>
                                <DialogContent>Are you sure you want to discard any changes?</DialogContent>
                                <DialogActions>
                                    <Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
                                        Cancel
                                    </Button>
                                    <Button appearance="primary" onClick={onDiscardChanges}>
                                        Discard
                                    </Button>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                </div>
            </div>
        </>
    );
}
