import { useEffect, useState } from "react";
import { AdminEventOptionSummary, Event } from "../../models/Admin";
import { Button, Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableBody, TableCellLayout } from "@fluentui/react-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as api from "../../utils/api";
import { useTableStyles } from "../../hooks/useDialogStyles";

export const AdminEventOptionList = () => {
    const navigate = useNavigate();
    const { eventSlug } = useParams() as { eventSlug: string };

    const [event, setEvent] = useState<Event | null>(null);
    const [options, setOptions] = useState<AdminEventOptionSummary[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [isCallingApi, setIsCallingApi] = useState(true);

    useEffect(() => {
        async function execute() {
            try {
                setIsCallingApi(true);
                setError(null);
                setEvent(await api.get<Event>(`api/admin/events/${eventSlug}`));
                setOptions(await api.get<AdminEventOptionSummary[]>(`api/admin/events/${eventSlug}/options`));
            } catch (e) {
                setError(e as Error);
            } finally {
                setIsCallingApi(false);
            }
        }
        execute();
    }, [eventSlug]);

    const tableStyles = useTableStyles();

    const header = (
        <h1>
            <Link to="/admin">admin</Link> / <Link to="/admin/events">events</Link> /{" "}
            <Link to={`/admin/events/${eventSlug}`}>{event?.title ?? eventSlug}</Link> / options
        </h1>
    );

    if (error) {
        return (
            <>
                {header}
                <div>Error: {error.message}</div>
            </>
        );
    }
    if (isCallingApi) {
        return (
            <>
                {header}
                <div>loading...</div>
            </>
        );
    }
    function getStatusString(option: AdminEventOptionSummary) {
        if (!option.active) {
            return "Inactive";
        }
        if (option.validFrom) {
            if (option.validUntil) {
                return `Active from ${option.validFrom} to ${option.validUntil}`;
            }
            return `Active from ${option.validFrom}`;
        }
        if (option.validUntil) {
            return `Active until ${option.validUntil}`;
        }
        return "Active";
    }

    return (
        <>
            {header}
            <Table className="table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell className={tableStyles.thead}>ID</TableHeaderCell>
                        <TableHeaderCell className={tableStyles.thead}>Title</TableHeaderCell>
                        <TableHeaderCell className={tableStyles.thead}>Stripe ID</TableHeaderCell>
                        <TableHeaderCell className={tableStyles.thead}>Status</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {options?.map((option) => (
                        <TableRow key={option.id}>
                            <TableCell>
                                <TableCellLayout appearance="primary">
                                    <Link to={`/admin/events/${eventSlug}/options/${option.id}`}>{option.id}</Link>
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>{option.title}</TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>
                                    <a href={option.stripeUrl}>{option.stripeId}</a>
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>{getStatusString(option)}</TableCellLayout>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button onClick={() => navigate(`/admin/events/${eventSlug}/options/$new`)}>New Option</Button>
        </>
    );
};
